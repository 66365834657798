import React, { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

type MultiSelectProps = {
  selected: (number | string)[];
  options: (number | string)[];
  setSelected: (value: (number | string)[]) => void;
  label?: string;
  placeholder?: string;
  id?: string;
  style?: any;
  showSelectedValues?: boolean;
  maxValuesAllowed?: number;
};

export const MultiSelect = ({
  options,
  selected,
  setSelected,
  label,
  placeholder,
  id,
  style,
  showSelectedValues = true,
  maxValuesAllowed = 100,
}: MultiSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleBackgroundClick(e: any) {
      const elem = document.getElementById(id || 'select');
      if (!elem?.contains(e.target)) setIsOpen(false);
    }
    document.addEventListener('mousedown', handleBackgroundClick);
    // cleanup, remove event listener
    return () => {
      document.removeEventListener('mousedown', handleBackgroundClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Listbox
      value={selected}
      onChange={(val: any) => {
        if (selected.includes(val)) {
          setSelected(selected.filter((v) => v !== val));
        } else {
          setSelected([...selected, val]);
        }
      }}
      as="div"
      onClick={() => setIsOpen(!isOpen)}
      id={id || 'select'}
    >
      {() => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
          )}
          <div className="mt-1 relative">
            <Listbox.Button
              style={{ minHeight: 38, ...style }}
              className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {placeholder?.length && !selected.length && (
                <span className="text-gray-700">{placeholder}</span>
              )}
              {showSelectedValues && selected.length ? (
                <span className="block truncate">{selected.join(', ')}</span>
              ) : null}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={isOpen}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {options.map((option) => {
                  const isSelected = selected.includes(option);
                  const isDisabled =
                    selected.length === maxValuesAllowed &&
                    !selected.includes(option);
                  return (
                    <Listbox.Option
                      key={option}
                      disabled={isDisabled}
                      className={({ active }) =>
                        classNames(
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                          active && !isSelected && 'bg-indigo-600',
                          isSelected && !active && 'bg-blue-100',
                          active && isSelected && 'bg-indigo-600',
                        )
                      }
                      value={option}
                    >
                      {({ active }) => (
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id={option.toString()}
                              name={option.toString()}
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              checked={selected.includes(option)}
                              readOnly
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              className={classNames(
                                'block truncate',
                                active && !isSelected && 'text-white',
                                isSelected &&
                                  !active &&
                                  'font-semibold text-gray-900',
                                active &&
                                  isSelected &&
                                  'font-semibold text-white',
                              )}
                            >
                              {option}
                            </label>
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
