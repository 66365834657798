import { Claim, ClaimItemValues } from 'types/claim';
import { Text } from './Text';
import { Itempill } from 'components/Itempill';
import { format } from 'date-fns';
import { ClaimResolutionActivities } from './ClaimResolutionActivities';

type ResolutionDenyProps = {
  resolution: any;
  claim: Claim;
  claimItem: ClaimItemValues;
};
export const ResolutionDeny = ({
  resolution,
  claim,
  claimItem,
}: ResolutionDenyProps) => {
  return (
    <div className="mt-5">
      <div className="flex">
        <Text value={`Issue ${claim.number}`} type="h4" className="mr-2" />
        <Text
          value="Deny"
          type="bold"
          className="bg-red-100 px-3 py-1 rounded-md text-red-700 text-center w-fit"
        />
      </div>
      <div className="flex items-center mt-2">
        <Text
          value="Date Updated:"
          className="mr-2 font-bold text-gray-500"
          type="sm"
        />
        <Text
          value={`${format(
            new Date(resolution.updatedAt),
            'do MMM yyyy hh:mm',
          )}`}
          type="sm"
          className="text-gray-500"
        />
      </div>
      <div className="sm:flex gap-4 sm:overflow-none overflow-x-scroll mt-5">
        <div className="w-full sm:w-2/3 h-fit">
          <div className="border border-gray-300 p-3 rounded-md ">
            <Text
              value="Reason"
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md"
            />
            <Text value={resolution.message} className="text-gray-500 py-4" />
          </div>

          <ClaimResolutionActivities claim={claim} claimItem={claimItem} />
        </div>
        <div className="border border-gray-300 p-3 rounded-md w-full sm:w-1/3 sm:mt-0 mt-4">
          <Text
            value="Customer"
            type="h4"
            className="px-3 py-2 bg-gray-50 rounded-md"
          />
          <div className="border-b border-gray-300 pb-4">
            <Text
              value={claim?.order.customerName}
              type="body"
              className="text-gray-500 mt-4"
            />
            <span className="flex mt-2">
              <Text
                value={claim?.items.length}
                type="bold"
                className="text-blue-500"
              />
              <Text value="Items" className="text-gray-500 ml-2" />
            </span>
          </div>
          <div className=" py-4">
            <Text value="Contact information" type="bold" className="" />
            <Text
              value={claim?.order.customerEmail ?? 'N/A'}
              type="body"
              className="text-gray-500 mt-4"
            />
            <Text
              value={claim?.order.customerPhone ?? 'N/A'}
              type="body"
              className="text-gray-500 mt-4"
            />
          </div>
          <div className=" mt-4 pb-4">
            <Text
              value="Notes"
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md"
            />
            <Text value={claim?.notes} className="text-gray-500 mt-4" />
          </div>
          <div className="py-4">
            <Text
              value="Tags"
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md"
            />
            {claim.tags.map((tag, i: number) => (
              <Itempill item={tag} key={i} className="mr-2 h-max mt-3 w-fit" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
