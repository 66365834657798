import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import { POST_INSTALL_REDIRECT } from 'gql/mutations';
import { useAuthenticationStatus } from '@nhost/react';
import { ActionPage } from './NestedContainers/ActionPage';
import { useUserData } from '@nhost/react';
import { Text } from 'components/Text';

export function PostInstall() {
  const [postInstallError, setPostInstallError] = useState('');
  const navigate = useNavigate();
  const user = useUserData();

  const [postInstallMutation, { called }] = useMutation(POST_INSTALL_REDIRECT, {
    onError: (error) => {
      setPostInstallError(
        error.message || 'An error occured. Please try again.',
      );
      localStorage.removeItem('shipaid___post-install');
    },
    onCompleted: (data) => {
      const STORE_ID_KEY = `${user?.id}-shipaid___storeId`;
      const STORE_DOMAIN_KEY = `${user?.id}-shipaid___domain`;
      const STORE_NAME_KEY = `${user?.id}-shipaid___name`;

      localStorage.setItem(STORE_ID_KEY, data.handleInstallRedirect.id);
      localStorage.setItem(STORE_DOMAIN_KEY, data.handleInstallRedirect.store);
      localStorage.setItem(STORE_NAME_KEY, data.handleInstallRedirect.store);
      setTimeout(() => {
        // TODO: @stefan: We need a user friendly UI for the 7 second 5 wait
        localStorage.removeItem('shipaid___post-install');
        navigate('/dashboard');
      }, 5000);
    },
  });

  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuthenticationStatus();

  useEffect(() => {
    if (!called && isAuthenticated) {
      const searchParams = new URLSearchParams(location.search);
      postInstallMutation({
        variables: {
          code: searchParams.get('code'),
          store: searchParams.get('shop'),
          searchParams: Object.fromEntries(searchParams),
        },
      });
    } else if (!isLoading && !isAuthenticated) {
      console.warn('not authenticated, redirecting');
      const signupUrl = 'https://app.shipaid.com/sign-up';
      localStorage.setItem('shipaid___post-install', window.location.href);
      window.location.href = signupUrl;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    called,
    postInstallMutation,
    isAuthenticated,
    location.search,
    isLoading,
  ]);

  return (
    <div>
      {postInstallError ? (
        <ActionPage
          title="Oops!"
          subtitle={postInstallError}
          onClick={() => navigate('/dashboard')}
        />
      ) : (
        <div className="flex justify-center flex-col items-center min-h-screen">
          <Text
            type="h4"
            value={
              'Please wait while we get you account set up and store connected. This could take a few minutes. Please DO NOT REFRESH...'
            }
            className="mb-2"
          />
        </div>
      )}
    </div>
  );
}
