import { Text } from 'components/Text';
import { Button } from '../../../components/Button';

export const columns = [
  {
    name: 'Platform',
    id: 1,
    render: () => <Text value={'Shopify'} />,
  },
  {
    name: 'Store',
    id: 2,
    render: (item: any) => <Text value={item.name} />,
  },
  {
    name: 'Permissions',
    id: 3,
    render: (item: any) => (
      <div className="flex items-center">
        <Button
          disabled={!item.isPermissionUpdateRequired || item.loading}
          onClick={item.updatePermission}
          className="bg-blue-600 hover:bg-blue-700"
        >
          Update Permissions
        </Button>
      </div>
    ),
  },
];
