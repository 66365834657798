import React from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { FixedRule } from 'components/FixedRule';
import { RuleItem } from 'types/plans';

type FixedModalProps = {
  show: boolean;
  setShowModal: (value: boolean) => void;
  rules: RuleItem[];
  addNewRule: () => void;
  removeRule: (index: number) => void;
  onChangeRuleValue: (field: string, value: string, index: number) => void;
  handleSetRule?: (type: string) => void;
  isLoading?: boolean;
  defaultFee: string;
  setDefaultFee: (fee: string) => void;
};

export const FixedModal = ({
  show,
  setShowModal,
  rules,
  addNewRule,
  removeRule,
  onChangeRuleValue,
  handleSetRule,
  isLoading,
  defaultFee,
  setDefaultFee,
}: FixedModalProps) => {
  const handleSave = () => {
    handleSetRule && handleSetRule('fixed');
  };

  const handleFieldUpdate = (value: string) => {
    const num = Number(value);
    if (isNaN(num)) {
      return;
    }
    setDefaultFee(value);
  };

  return (
    <Modal
      openModal={(value) => setShowModal(value)}
      open={show}
      containerClass="sm:max-w-lg sm:w-full"
    >
      <Text value="Guarantee fee" type="h4" />
      <div className="block sm:flex sm:items-center">
        <Text
          value="Default guarantee fee (static):"
          type="sm"
          className="text-gray-500 mr-3 mb-2 sm:mb-0"
        />
        <input
          name="defaultFee"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          placeholder="e.g $3.00"
          value={defaultFee}
          onChange={(e) =>
            handleFieldUpdate(e.target.value.replace(/[$£€CA$]/g, ''))
          }
        />
      </div>
      <div className="py-3">
        {rules.length > 0 &&
          rules.map((rule, index) => (
            <FixedRule
              key={index}
              onRemoveItem={() => removeRule(index)}
              rule={rule}
              onChange={(field, value) =>
                onChangeRuleValue(field, value, index)
              }
            />
          ))}
        <Button
          onClick={() => addNewRule()}
          className="bg-transparent mr-3 border border-gray-500  hover:bg-gray-100 mt-4"
        >
          <Text value="Add new rule" className="text-gray-500" />
        </Button>
      </div>
      <div className="flex mt-6">
        <Button
          onClick={handleSave}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
          isLoading={isLoading}
        >
          Save Changes
        </Button>
        <Button
          onClick={() => setShowModal(false)}
          className="bg-transparent border border-rose-500 text-gray-500 hover:bg-rose-100"
        >
          <Text value="Cancel" className="text-rose-500" />
        </Button>
      </div>
    </Modal>
  );
};
