import React, { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { LoadingScreen } from 'components/LoadingScreen';
import { apiErrorHandler, SizeEnum } from 'utils';
import { useMutation } from '@apollo/client';
import { useStore } from 'context/store-context';
import classNames from 'classnames';
import { UPDATE_STORE } from 'gql/mutations';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { TeamImageUpload } from 'components/TeamImageUpload';
import { CustomerPortalBtn } from 'components/CustomerPortalBtn';

export const Customer = withNotification(({ showNotification }: any) => {
  const store = useStore();
  const [teamData, setTeamData] = useState({
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    companyWebsite: '',
    logoUrl: '',
    bannerUrl: '',
    requireDeliveryGuaranteePolicy: false,
    policyUrl: '',
    requirePreferredResolution: false,
    isPreferredResolutionMandatory: false,
    isLateClaimCauseEnabled: true,
    isStolenClaimCauseEnabled: true,
    requireVideoUpload: false,
    isVideoUploadMandatory: false,
  });
  const { storeId } = store;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);

  const [
    updateStore,
    { error: updateStoreError, loading: updateStoreLoading },
  ] = useMutation(UPDATE_STORE, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    if (store.storeProperties) {
      setTeamData({
        companyName: store.storeProperties?.name ?? '',
        companyEmail: store.storeProperties?.contactEmail ?? '',
        companyPhone: store.storeProperties?.contactPhone ?? '',
        companyWebsite: store.storeProperties?.store ?? '',
        logoUrl: store.storeProperties.logoUrl ?? '',
        bannerUrl: store.storeProperties.bannerUrl ?? '',
        requireDeliveryGuaranteePolicy:
          store.storeProperties.requireDeliveryGuaranteePolicy ?? false,
        policyUrl: store.storeProperties.policyUrl ?? '',
        requirePreferredResolution:
          store.storeProperties.resolvePortalOptions
            .requirePreferredResolution ?? false,
        isPreferredResolutionMandatory:
          store.storeProperties.resolvePortalOptions
            .isPreferredResolutionMandatory ?? false,
        isLateClaimCauseEnabled:
          store.storeProperties.resolvePortalOptions.isLateClaimCauseEnabled ??
          true,
        isStolenClaimCauseEnabled:
          store.storeProperties.resolvePortalOptions
            .isStolenClaimCauseEnabled ?? true,
        requireVideoUpload:
          store.storeProperties.resolvePortalOptions.requireVideoUpload ??
          false,
        isVideoUploadMandatory:
          store.storeProperties.resolvePortalOptions.isVideoUploadMandatory ??
          false,
      });
    }
  }, [store.storeProperties, setTeamData]);

  useEffect(() => {
    if (
      store.storeProperties &&
      !store.storeProperties.completedSteps?.hasVisitedResolutionPage
    ) {
      updateStore({
        variables: {
          storeId,
          set: {
            completedSteps: {
              ...store.storeProperties.completedSteps,
              hasVisitedResolutionPage: true,
            },
          },
        },
      });
    }
  });

  useEffect(() => {
    if (selectedUser) {
      setShowConfirmationModal(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!showConfirmationModal) {
      setSelectedUser(null);
    }
  }, [showConfirmationModal]);

  const handleChangeTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setTeamData({ ...teamData, [name]: value });
  };

  const handleUpdateStore = () => {
    if (
      teamData.requireDeliveryGuaranteePolicy &&
      (!teamData.policyUrl || teamData.policyUrl === '')
    ) {
      return showNotification(
        NotificationTypes.error,
        'Please provide a valid Policy URL',
      );
    }
    updateStore({
      variables: {
        storeId,
        set: {
          contactEmail: teamData.companyEmail,
          name: teamData.companyName,
          contactPhone: teamData.companyPhone,
          policyUrl: teamData.policyUrl,
          requireDeliveryGuaranteePolicy:
            teamData.requireDeliveryGuaranteePolicy,
          resolvePortalOptions: {
            requirePreferredResolution: teamData.requirePreferredResolution,
            isPreferredResolutionMandatory:
              teamData.isPreferredResolutionMandatory,
            isLateClaimCauseEnabled: teamData.isLateClaimCauseEnabled,
            isStolenClaimCauseEnabled: teamData.isStolenClaimCauseEnabled,
            requireVideoUpload: teamData.requireVideoUpload,
            isVideoUploadMandatory: teamData.isVideoUploadMandatory,
          },
        },
      },
    })
      .then((data) => {
        setSelectedField(null);
        return showNotification(
          NotificationTypes.success,
          'Company details updated',
        );
      })
      .catch((e) => {
        return showNotification(
          NotificationTypes.error,
          updateStoreError ? updateStoreError.message : 'Details not updated',
        );
      });
  };

  const handleCompanyLogoUpdate = (
    imgUrl: string,
    field: string,
    label: string = 'Image',
  ) => {
    updateStore({
      variables: { storeId, set: { [field]: imgUrl } },
    })
      .then((data) => {
        setSelectedField(null);
        store.refetchStoreProperties && store.refetchStoreProperties();
        return showNotification(
          NotificationTypes.success,

          `${label} updated`,
        );
      })
      .catch((err) => {
        return showNotification(
          NotificationTypes.error,
          updateStoreError
            ? updateStoreError.message
            : `Could not update ${label.toLowerCase()} photo`,
        );
      });
  };

  if (store?.storeLoading && !store?.storeProperties) return <LoadingScreen />;

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex items-center justify-between max-w-2xl">
          <Text value="Company" type="h1" />
          <div>
            <CustomerPortalBtn />
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
        <TeamImageUpload
          onUpload={({ urls }) =>
            handleCompanyLogoUpdate(urls[0], 'logoUrl', 'Company logo')
          }
          image={teamData?.logoUrl}
          isLoading={updateStoreLoading}
          id="logo"
          heading="Company Logo"
          subheading="Click image to select (min resolution for best results: 150px x 150px. Maintain aspect ratio)"
        />
        <div className="mt-3">
          <TeamImageUpload
            onUpload={({ urls }) =>
              handleCompanyLogoUpdate(urls[0], 'bannerUrl', 'Company banner')
            }
            id="banner"
            image={teamData?.bannerUrl}
            isLoading={updateStoreLoading}
            heading="Company Banner"
            subheading="Click image to select (min resolution for best results: 1280px x 240px. Maintain aspect ratio)"
          />
          <div
            className="flex w-full sm:w-1/2 justify-between py-4"
            style={{ width: '50%' }}
          >
            <div>
              <Text
                value="Company Email"
                type="body"
                className="text-gray-600 font-bold"
              />
              <input
                type="text"
                name="companyEmail"
                id="companyEmail"
                className={classNames(
                  selectedField === 'companyEmail'
                    ? 'border-b border-blue-400 focus:border-blue-400'
                    : 'bg-transparent',
                  'focus:ring-0 focus:border-0 block w-full sm:text-sm outline-0 text-gray-600 py-2 pr-2',
                )}
                placeholder="you@example.com"
                value={teamData.companyEmail}
                onChange={handleChangeTeam}
                disabled={selectedField === 'companyEmail' ? false : true}
              />
            </div>
            <Button
              onClick={() => setSelectedField('companyEmail')}
              size={SizeEnum.md}
              className="h-max px-6"
            >
              Edit
            </Button>
          </div>

          <div
            className="flex w-full sm:w-1/2 justify-between py-4"
            style={{ width: '50%' }}
          >
            <div>
              <Text
                value="Company Name"
                type="body"
                className="text-gray-600 font-bold"
              />
              <input
                type="text"
                name="companyName"
                id="email"
                className={classNames(
                  selectedField === 'companyName'
                    ? 'border-b border-blue-400'
                    : 'bg-transparent',
                  'focus:ring-0 focus:border-0 block w-full sm:w-96 sm:text-sm outline-0 text-gray-600 py-2 pr-2',
                )}
                placeholder="you@example.com"
                value={teamData.companyName}
                onChange={handleChangeTeam}
                disabled={selectedField === 'companyName' ? false : true}
              />
            </div>
            <Button
              onClick={() => setSelectedField('companyName')}
              size={SizeEnum.md}
              className="h-max px-6"
            >
              Edit
            </Button>
          </div>

          <div
            className="flex w-full sm:w-1/2 justify-between py-4"
            style={{ width: '50%' }}
          >
            <div>
              <Text
                value="Company Phone"
                type="body"
                className="text-gray-600 font-bold"
              />
              <input
                type="text"
                name="companyPhone"
                id="email"
                className={classNames(
                  selectedField === 'companyPhone'
                    ? 'border-b border-blue-400 focus:border-blue-400'
                    : 'bg-transparent',
                  'focus:ring-0 focus:border-0 block w-full sm:text-sm outline-0 text-gray-600 py-2 pr-2',
                )}
                placeholder="eg: +1-541-754-3010"
                value={teamData.companyPhone}
                onChange={handleChangeTeam}
                disabled={selectedField === 'companyPhone' ? false : true}
              />
            </div>
            <Button
              onClick={() => setSelectedField('companyPhone')}
              size={SizeEnum.md}
              className="h-max px-6"
            >
              Edit
            </Button>
          </div>

          <div
            className="flex w-full sm:w-1/2 justify-between py-4"
            style={{ width: '50%' }}
          >
            <div>
              <Text
                value="Company Store Domain"
                type="body"
                className="text-gray-600 font-bold"
              />
              <Text
                value={teamData.companyWebsite}
                className="py-2 pr-2 text-gray-600"
                type="sm"
              />
            </div>
            {/* <Button
              onClick={() => setSelectedField('companyWebsite')}
              size={SizeEnum.md}
              className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
            >
              Edit
            </Button> */}
          </div>

          <div
            className="md:flex justify-between items-center py-4"
            style={{ width: '50%' }}
          >
            <div className="md:flex items-center">
              <Text
                value="Refund Policy"
                type="body"
                className="text-gray-600 font-bold"
              />
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    requireDeliveryGuaranteePolicy:
                      !teamData.requireDeliveryGuaranteePolicy,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6"
              >
                {teamData.requireDeliveryGuaranteePolicy ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Enable
              </Button>
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    requireDeliveryGuaranteePolicy:
                      !teamData.requireDeliveryGuaranteePolicy,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6 ml-2"
              >
                {!teamData.requireDeliveryGuaranteePolicy ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Disable
              </Button>
            </div>
          </div>
          <div
            className="flex w-full sm:w-1/2 justify-between py-4"
            style={{ width: '50%' }}
          >
            <div>
              <Text
                value="Policy URL"
                type="body"
                className="text-gray-600 font-bold"
              />
              <input
                type="text"
                name="policyUrl"
                id="policyUrl"
                className={classNames(
                  selectedField === 'policyUrl'
                    ? 'border-b border-blue-400'
                    : 'bg-transparent',
                  'focus:ring-0 focus:border-0 block w-full sm:w-96 sm:text-sm outline-0 text-gray-600 py-2 pr-2',
                )}
                // placeholder="you@example.com"
                value={teamData.policyUrl}
                onChange={handleChangeTeam}
                disabled={selectedField === 'policyUrl' ? false : true}
              />
            </div>
            <Button
              onClick={() => setSelectedField('policyUrl')}
              size={SizeEnum.md}
              className="h-max px-6"
            >
              Edit
            </Button>
          </div>

          <div
            className="md:flex justify-between items-center py-4"
            style={{ width: '50%' }}
          >
            <div className="md:flex items-center">
              <Text
                value="Preferred Resolution"
                type="body"
                className="text-gray-600 font-bold"
              />
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    requirePreferredResolution:
                      !teamData.requirePreferredResolution,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6"
              >
                {teamData.requirePreferredResolution ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Enable
              </Button>
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    requirePreferredResolution:
                      !teamData.requirePreferredResolution,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6 ml-2"
              >
                {!teamData.requirePreferredResolution ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Disable
              </Button>
            </div>
          </div>
          {teamData.requirePreferredResolution ? (
            <div
              className="md:flex justify-between items-center py-4"
              style={{ width: '50%' }}
            >
              <div className="md:flex items-center">
                <Text
                  value="Mandatory Preferred Resolution"
                  type="body"
                  className="text-gray-600 font-bold"
                />
              </div>
              <div className="flex justify-end">
                <Button
                  onClick={() =>
                    setTeamData({
                      ...teamData,
                      isPreferredResolutionMandatory:
                        !teamData.isPreferredResolutionMandatory,
                    })
                  }
                  size={SizeEnum.md}
                  className="h-max px-6"
                >
                  {teamData.isPreferredResolutionMandatory ? (
                    <CheckIcon className="mr-1" width={20} />
                  ) : null}{' '}
                  Enable
                </Button>
                <Button
                  onClick={() =>
                    setTeamData({
                      ...teamData,
                      isPreferredResolutionMandatory:
                        !teamData.isPreferredResolutionMandatory,
                    })
                  }
                  size={SizeEnum.md}
                  className="h-max px-6 ml-2"
                >
                  {!teamData.isPreferredResolutionMandatory ? (
                    <CheckIcon className="mr-1" width={20} />
                  ) : null}{' '}
                  Disable
                </Button>
              </div>
            </div>
          ) : null}
          <div
            className="md:flex justify-between items-center py-4"
            style={{ width: '50%' }}
          >
            <div className="md:flex items-center">
              <Text
                value="Issue Cause: Late"
                type="body"
                className="text-gray-600 font-bold"
              />
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    isLateClaimCauseEnabled: !teamData.isLateClaimCauseEnabled,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6"
              >
                {teamData.isLateClaimCauseEnabled ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Enable
              </Button>
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    isLateClaimCauseEnabled: !teamData.isLateClaimCauseEnabled,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6 ml-2"
              >
                {!teamData.isLateClaimCauseEnabled ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Disable
              </Button>
            </div>
          </div>
          <div
            className="md:flex justify-between items-center py-4"
            style={{ width: '50%' }}
          >
            <div className="md:flex items-center">
              <Text
                value="Issue Cause: Stolen"
                type="body"
                className="text-gray-600 font-bold"
              />
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    isStolenClaimCauseEnabled:
                      !teamData.isStolenClaimCauseEnabled,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6"
              >
                {teamData.isStolenClaimCauseEnabled ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Enable
              </Button>
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    isStolenClaimCauseEnabled:
                      !teamData.isStolenClaimCauseEnabled,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6 ml-2"
              >
                {!teamData.isStolenClaimCauseEnabled ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Disable
              </Button>
            </div>
          </div>
          <div
            className="md:flex justify-between items-center py-4"
            style={{ width: '50%' }}
          >
            <div className="md:flex items-center">
              <Text
                value="Video Upload"
                type="body"
                className="text-gray-600 font-bold"
              />
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    requireVideoUpload: !teamData.requireVideoUpload,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6"
              >
                {teamData.requireVideoUpload ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Enable
              </Button>
              <Button
                onClick={() =>
                  setTeamData({
                    ...teamData,
                    requireVideoUpload: !teamData.requireVideoUpload,
                    isVideoUploadMandatory: false,
                  })
                }
                size={SizeEnum.md}
                className="h-max px-6 ml-2"
              >
                {!teamData.requireVideoUpload ? (
                  <CheckIcon className="mr-1" width={20} />
                ) : null}{' '}
                Disable
              </Button>
            </div>
          </div>
          {teamData.requireVideoUpload && (
            <div
              className="md:flex justify-between items-center py-4"
              style={{ width: '50%' }}
            >
              <div className="md:flex items-center">
                <Text
                  value="Video Upload Mandatory"
                  type="body"
                  className="text-gray-600 font-bold"
                />
              </div>
              <div className="flex justify-end">
                <Button
                  onClick={() =>
                    setTeamData({
                      ...teamData,
                      isVideoUploadMandatory: !teamData.isVideoUploadMandatory,
                    })
                  }
                  size={SizeEnum.md}
                  className="h-max px-6"
                >
                  {teamData.isVideoUploadMandatory ? (
                    <CheckIcon className="mr-1" width={20} />
                  ) : null}{' '}
                  Enable
                </Button>
                <Button
                  onClick={() =>
                    setTeamData({
                      ...teamData,
                      isVideoUploadMandatory: !teamData.isVideoUploadMandatory,
                    })
                  }
                  size={SizeEnum.md}
                  className="h-max px-6 ml-2"
                >
                  {!teamData.isVideoUploadMandatory ? (
                    <CheckIcon className="mr-1" width={20} />
                  ) : null}{' '}
                  Disable
                </Button>
              </div>
            </div>
          )}
          <Button
            onClick={handleUpdateStore}
            size={SizeEnum.md}
            className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4"
            isLoading={updateStoreLoading}
          >
            Update Details
          </Button>
        </div>
      </div>
    </div>
  );
});
