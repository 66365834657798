import React from 'react';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Toggle } from 'components/Toggle';

export const columns = [
  {
    name: 'Rule',
    id: 1,
    render: (item: any) => <Text value={item.title} type="sm" />,
  },
  {
    name: 'Resolution Action',
    id: 2,
    render: (item: any) => <Text value={item.action} type="sm" />,
  },
  {
    name: 'Status',
    id: 3,
    render: (item: any) => {
      return (
        <Toggle
          isEnabled={item.active}
          onChange={item.updateRuleStatus}
          isSmall
        />
      );
    },
  },
  {
    name: 'Actions',
    id: 4,
    render: (item: any) => {
      return (
        <div>
          <Button isRounded onClick={item.editRule}>
            Edit
          </Button>
          <Button
            isRounded
            className="ml-1 bg-red-500 text-white hover:bg-red-700 h-max px-6 mt-3"
            onClick={item.deleteRule}
          >
            Delete
          </Button>
        </div>
      );
    },
  },
];
