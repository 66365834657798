import React, { useEffect, useState } from 'react';
import { StoreContextInterface, UserStore } from 'types/store';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_STORE_PROPERTIES,
  GET_STORE_ANALYTICS,
  GET_STORES,
} from 'gql/queries';
import { useUserData } from '@nhost/react';
import { format, endOfDay } from 'date-fns';
import { isShipaidAdmin } from 'utils';
import { getDateXDaysAgo } from 'utils';

const StoreContext = React.createContext<StoreContextInterface>({});

const storeColors = [
  'bg-red-600',
  'bg-blue-600',
  'bg-green-600',
  'bg-orange-600',
  'bg-violet-600',
  'bg-purple-600',
  'bg-pink-600',
  'bg-rose-600',
  'bg-indigo-600',
  'bg-cyan-600',
];

const StoreProvider = (props: any) => {
  const adminData = localStorage.getItem(
    process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
  );
  let adminContext: any;
  if (adminData) adminContext = JSON.parse(adminData);
  const [userStores, setUserStores] = useState<UserStore[] | null>(null);

  const user = useUserData();
  const isUserShipaidAdmin = isShipaidAdmin(user);
  const STORE_ID_KEY = `${user?.id}-shipaid___storeId`;
  const STORE_DOMAIN_KEY = `${user?.id}-shipaid___domain`;
  const STORE_NAME_KEY = `${user?.id}-shipaid___name`;

  const [{ storeId, domain, name }, setUserStore] = useState<{
    storeId: string | null;
    domain: string | null;
    name: string | null;
  }>({
    storeId: localStorage.getItem(STORE_ID_KEY),
    domain: localStorage.getItem(STORE_DOMAIN_KEY),
    name: localStorage.getItem(STORE_NAME_KEY),
  });

  const getStoreInitials = (string: string) => {
    const a = string.split(/[,.-\s]/);
    const initials = [a[0].slice(0, 1), a[1]?.slice(0, 1)]
      .join('')
      .toUpperCase();

    return initials;
  };

  const getStoreColor = (index: number) => {
    return storeColors[index] || Math.floor(Math.random() * storeColors.length);
  };

  const { loading: isUserStoresLoading } = useQuery(GET_STORES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ stores }) => {
      setUserStores(
        stores.map(
          (store: any, i: number): UserStore => ({
            storeId: store.id,
            initials: getStoreInitials(store.name),
            storeColor: getStoreColor(i) + '',
            name: store.name || store.domain,
            domain: store.store,
            plan: store.plan,
            completedSteps: store.completedSteps,
          }),
        ),
      );
    },
    skip: isUserShipaidAdmin,
  });

  const [
    fetchStoreProperties,
    { data: storeProperties, loading: storeLoading },
  ] = useLazyQuery(GET_STORE_PROPERTIES, { fetchPolicy: 'cache-and-network' });

  const [
    fetchStoreAnalytics,
    { data: storeAnalytics, loading: analyticsLoading },
  ] = useLazyQuery(GET_STORE_ANALYTICS);

  const updateUserStore = (store: UserStore) => {
    setUserStore(store);
    localStorage.setItem(STORE_ID_KEY, store.storeId);
    localStorage.setItem(STORE_DOMAIN_KEY, store.domain);
    localStorage.setItem(STORE_NAME_KEY, store.name);
  };

  useEffect(() => {
    if (storeId && userStores) {
      const store = userStores.find((store) => {
        return store.storeId === storeId;
      });

      if (!store) {
        setUserStore({
          storeId: null,
          domain: null,
          name: null,
        });
      }
    }
  }, [storeId, userStores]);

  useEffect(() => {
    if (storeId && domain) {
      fetchStoreProperties({
        variables: { storeId },
      });
      fetchStoreAnalytics({
        variables: {
          storeId,
          domain,
          afterDate: format(endOfDay(getDateXDaysAgo(30)), 'yyyy-MM-dd'),
          beforeDate: format(endOfDay(getDateXDaysAgo(0)), 'yyyy-MM-dd'),
        },
      });
    }
  }, [
    fetchStoreProperties,
    fetchStoreAnalytics,
    storeId,
    domain,
    isUserShipaidAdmin,
  ]);

  const getStoreAnalytics = (from: string, to: string) => {
    fetchStoreAnalytics({
      variables: {
        storeId,
        domain,
        afterDate: from,
        beforeDate: to,
      },
    });
  };

  const getStoreProperties = () => {
    fetchStoreProperties({
      variables: { storeId },
    });
  };

  if (adminContext?.admin?.user?.user?.id && adminContext?.storeContextData) {
    return (
      <StoreContext.Provider
        value={{
          ...adminContext.storeContextData,
          updateUserStore,
          refetchStoreProperties: getStoreProperties,
          getStoreAnalytics,
          getStoreInitials,
          getStoreColor,
        }}
        {...props}
      />
    );
  }

  return (
    <StoreContext.Provider
      value={{
        userStores,
        storeId,
        isLoading: isUserStoresLoading,
        updateUserStore,
        storeDomain: domain,
        storeName: name,
        storeProperties: storeProperties?.store,
        storeLoading,
        analyticsLoading,
        refetchStoreProperties: getStoreProperties,
        storeAnalytics,
        getStoreAnalytics,
        getStoreInitials,
        getStoreColor,
      }}
      {...props}
    />
  );
};

function useStore() {
  const context = React.useContext(StoreContext);

  if (context === undefined) {
    throw new Error(`useStore must be used within a StoreProvider`);
  }
  return context;
}

export { StoreProvider, useStore };
