import React, { useState } from 'react';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { ClaimItemValues } from 'types/claim';
import { OrderItem } from 'components/OrderItem';

type DenyProps = {
  handleDenyItems?: (input: any) => void;
  loading?: boolean;
  claimItems?: ClaimItemValues[];
  staticClaimItems?: ClaimItemValues[];
  handleRemoveItem?: (order: ClaimItemValues) => void;
  handleQuantityUpdate?: (value: string, item: ClaimItemValues) => void;
};

export const Denyclaim = ({
  claimItems,
  staticClaimItems,
  handleDenyItems,
  loading,
  handleRemoveItem,
  handleQuantityUpdate,
}: DenyProps) => {
  const [input, setInput] = useState({
    message: '',
    notify: true,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    const { value } = e.target;

    setInput({ ...input, [field]: value });
  };

  const handleCheck = (field: string) => {
    setInput({ ...input, [field]: !input.notify });
  };

  return (
    <div className="block mt-4 w-full">
      <div className="border rounded p-5">
        <div className="flex justify-between px-3 py-2 bg-gray-50 rounded-md">
          <Text value="Deny claim item/s" type="bold" className="" />
        </div>
        <div className="overflow-y-auto mt-3">
          {claimItems && claimItems.length > 0 ? (
            claimItems.map((claimItem, index) => (
              <OrderItem
                key={claimItem.id}
                order={claimItem.orderItem}
                onRemoveItem={() =>
                  handleRemoveItem && handleRemoveItem(claimItem)
                }
                showIcon={false}
                onHandleQuantityUpdate={(value) =>
                  handleQuantityUpdate && handleQuantityUpdate(value, claimItem)
                }
                staticClaimItems={staticClaimItems} //claimItem that does not change when the quantity is updated
                claimItem={claimItem}
                index={index}
                isDisplay
              />
            ))
          ) : (
            <Text value="You need items" />
          )}
        </div>
        <div className="mt-6">
          <Text value="Reason" type="body" className="text-gray-500 mt-4" />
          <textarea
            rows={4}
            className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md mr-3 mt-3 p-2"
            placeholder="Reasons"
            onChange={(e) => handleChange(e, 'message')}
          />

          <Text
            type="sm"
            value={'NB - Reason is visible to customers on the customer portal'}
            className="text-gray-400"
          />
          <div className="mt-3 flex items-center">
            <input
              name="notify"
              type="checkbox"
              className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded mr-1"
              onChange={(e) => handleCheck('notify')}
              checked={input.notify}
            />
            <Text
              value="Send a notification to customers"
              className="text-gray-500"
              type="sm"
            />
          </div>
        </div>
        <div className="mt-4">
          <Button
            onClick={() => handleDenyItems && handleDenyItems(input)}
            isLoading={loading}
          >
            Deny Issue
          </Button>
        </div>
      </div>
    </div>
  );
};
