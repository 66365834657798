import { Text } from 'components/Text';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export function PostPayment() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/dashboard');
    }, 5000);
  }, [navigate]);

  return (
    <div className="flex justify-center flex-col items-center min-h-screen">
      <Text type="h2" value="Thank you for authorizing the billing" />
      <Text value="Now redirecting you to your dashboard..." />
    </div>
  );
}
