import { useStore } from 'context/store-context';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { XIcon } from '@heroicons/react/outline';
import { Button } from './Button';
import { SET_STORE_PLAN } from 'gql/mutations';
import { useMutation } from '@apollo/client';

export const MerchantChargeModal = ({
  isOpen,
  onClose,
}: {
  isOpen: null | boolean;
  onClose: () => void;
}) => {
  const { storeProperties, storeId } = useStore();

  const [setStorePlan, { loading: setStorePlanLoading }] = useMutation(
    SET_STORE_PLAN,
    {
      onCompleted: (data) => {
        const {
          setStorePlan: { chargeUrl },
        } = data;
        // redirect user to the chargeUrl link
        window.location.href = chargeUrl;
      },
      onError: (error) => {},
    },
  );

  const handleChargeReset = () => {
    setStorePlan({
      variables: {
        storeId,
      },
    });
  };

  const noPlan = !storeProperties?.plan?.chargeUrl;
  return (
    <Modal
      openModal={() => {}}
      open={
        Boolean(
          !storeProperties?.plan?.chargeConfirmed &&
            storeProperties?.plan?.chargeUrl,
        ) || Boolean(isOpen)
      }
      containerClass="sm:max-w-lg sm:w-full shadow-none"
      overlayClassName="bg-opacity-100 bg-white"
      isFull
    >
      <XIcon
        onClick={() => onClose()}
        className="w-5 h-5 absolute right-0 cursor-pointer"
        aria-hidden="true"
      />
      {noPlan ? (
        <Text value="Reset Store Billing (required)" type="h4" />
      ) : (
        <Text value="Authorize Shipaid Billing (required)" type="h4" />
      )}
      <div className="text-gray-500">
        <Text
          className="my-4 text-center"
          value={
            noPlan
              ? `Click the link below to reset and authorize the Shipaid billing on Shopify. You will be redirected once the billing has been authorized`
              : `Click the link below to authorize the Shipaid billing on Shopify. You will be redirected once the billing has been authorized`
          }
        />

        {noPlan ? (
          <Button
            onClick={handleChargeReset}
            className="bg-blue-600 hover:bg-blue-700 w-full justify-center"
            disabled={setStorePlanLoading}
          >
            Reset Billing
          </Button>
        ) : (
          <a rel="noreferrer" href={storeProperties?.plan?.chargeUrl}>
            <Button
              onClick={() => {}}
              className="bg-blue-600 hover:bg-blue-700 w-full justify-center"
            >
              Authorize Billing
            </Button>
          </a>
        )}
      </div>
    </Modal>
  );
};
