import { todos } from 'containers/NestedContainers/data/dashboard-data';
import { CheckIcon } from '@heroicons/react/solid';
import { Text } from 'components/Text';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

type TodosProps = {
  list: string[];
};

export const Todos = ({ list }: TodosProps) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg px-6 my-5">
      {todos.map((todo) => {
        return (
          <div
            key={todo.key}
            className="my-5 flex items-center cursor-pointer"
            onClick={() => navigate(todo.to)}
          >
            <div className="flex flex-col items-center justify-center w-8">
              <div
                className={classNames(
                  'rounded-full flex items-center justify-center font-bold',
                  list.includes(todo.key) ? 'bg-blue-700' : 'bg-gray-400',
                  list.includes(todo.key) ? 'h-5 w-5' : 'h-3 w-3',
                )}
              >
                {list.includes(todo.key) && (
                  <CheckIcon
                    className="h-4 w-4 fill-white"
                    fill="white"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
            <Text
              value={todo.title}
              className={classNames(
                'ml-10',
                list.includes(todo.key) && 'text-black font-semibold',
                !list.includes(todo.key) &&
                  !list.includes(todo.key) &&
                  'text-gray-600',
              )}
            />
          </div>
        );
      })}
    </div>
  );
};
