import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { Text } from 'components/Text';
import { Toggle } from 'components/Toggle';
import { Dropdown } from 'components/Dropdown';
import { Itempill } from 'components/Itempill';
import { Button } from 'components/Button';
import {
  getPreciseDecimal,
  getPriceValue,
  getStoreCurrencyFormatter,
  SizeEnum,
} from 'utils';
import { FixedModal } from 'components/FixedModal';
import { PackagePlan } from 'components/PackagePlan';
import { PercentageModal } from 'components/PercentageModal';
import { useStore } from 'context/store-context';
import { GET_PROTECTION_SETTINGS } from 'gql/queries';
import {
  UPDATE_PROTECTION_SETTINGS,
  UPDATE_STORE,
  UPDATE_STORE_CUSTOMER_AUTO_OPT_IN_TAGS,
} from 'gql/mutations';
import { RuleItem } from 'types/plans';
import { LoadingScreen } from 'components/LoadingScreen';
import { AllFixedRulesModal } from 'components/AllFixedRulesModal';
import { withNotification } from 'components/Notification';
import { NotificationTypes, apiErrorHandler } from 'utils';
import { CustomerPortalBtn } from 'components/CustomerPortalBtn';
import { TrackingPortalBtn } from 'components/TrackingPortalBtn';

export const Protection = withNotification(({ showNotification }: any) => {
  const [cartToggle, setCartToggle] = useState(false);
  const [discountOnReorders, setDiscountOnReorders] = useState(true);
  const [optInToggle, setOptInToggle] = useState(false);
  const [itemFulfillmentImmediate, setitemFulfillmentImmediate] =
    useState(false);
  const [ignoredSku, setIgnoredSku] = useState('');
  const [ignoredCustomerTag, setIgnoredCustomerTag] = useState('');
  const [defaultFee, setDefaultFee] = useState('3');
  const [percentageInput, setPercentageInput] = useState({
    maximumFee: '1500',
    minimumFee: '1',
    percentage: '3',
  });
  const [ignoredSkuArr, setIgnoredSkuArr] = useState<string[]>([]);
  const [ignoredCustomerTagsArr, setIgnoredCustomerTagsArr] = useState<
    string[]
  >([]);
  const [orderTags, setOrderTags] = useState('');
  const [orderTagsArr, setOrderTagsArr] = useState<string[]>([]);
  const [currency, setCurrency] = useState('');
  const [showFixedModal, setShowFixedModal] = useState(false);
  const [showAllFixedRulesModal, setShowAllFixedRulesModal] = useState(false);
  const [showPercentageModal, setShowPercentageModal] = useState(false);
  const [rules, setRules] = useState<RuleItem[]>([]);
  const [currentProtectionData, setCurrentProtectionData] = useState<any>();
  const [updatedCustomerTags, setUpdatedCustomerTags] = useState(false);
  const { storeId, storeProperties, refetchStoreProperties } = useStore();

  const [getProtectionSettings, { loading: protectionLoading }] = useLazyQuery(
    GET_PROTECTION_SETTINGS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        storeId,
      },
      onCompleted: (data) => {
        setCurrentProtectionData(data?.protectionSettings[0]);
        if (
          Array.isArray(data?.protectionSettings) &&
          data?.protectionSettings[0]?.rules
        ) {
          setRules(data?.protectionSettings[0].rules ?? []);
        }
        setPercentageInput({
          ...percentageInput,
          maximumFee:
            getStoreCurrencyFormatter(
              storeProperties?.currency,
              data?.protectionSettings[0].maximumFee,
            ) ?? '',
          minimumFee:
            getStoreCurrencyFormatter(
              storeProperties?.currency,
              data?.protectionSettings[0].minimumFee,
            ) ?? '',
          percentage: `${getPreciseDecimal(
            data?.protectionSettings[0]?.percentage * 100,
          )}`,
        });
      },
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  const [setProtectionSettings, { loading }] = useMutation(
    UPDATE_PROTECTION_SETTINGS,
    {
      onCompleted: () => {
        getProtectionSettings();
        refetchStoreProperties && refetchStoreProperties();
      },
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  const [
    updateStore,
    { error: updateStoreError, loading: updateStoreLoading },
  ] = useMutation(UPDATE_STORE, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const [updateCustomerExcludeTags] = useMutation(
    UPDATE_STORE_CUSTOMER_AUTO_OPT_IN_TAGS,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted() {
        setUpdatedCustomerTags(false);
        refetchStoreProperties && refetchStoreProperties();
      },
    },
  );

  useEffect(() => {
    getProtectionSettings();
  }, [getProtectionSettings, storeProperties]);

  useEffect(() => {
    if (storeProperties) {
      setCartToggle(storeProperties.widgetShowCart);
      setOptInToggle(storeProperties.widgetAutoOptIn);
      setDiscountOnReorders(storeProperties.reordersDiscount);
      setitemFulfillmentImmediate(storeProperties.itemFulfillmentImmediate);
      setCurrency(storeProperties.currency);
      setIgnoredSku(storeProperties.excludedProductSkus?.join(','));
      setIgnoredCustomerTag(
        storeProperties?.tagsExcludedCustomersAutoOptIn
          ? storeProperties?.tagsExcludedCustomersAutoOptIn?.join(',')
          : '',
      );
    }
  }, [storeProperties]);

  useEffect(() => {
    const skuArr = ignoredSku.split(',');
    setIgnoredSkuArr(skuArr);
  }, [ignoredSku]);

  useEffect(() => {
    const customerTagsArr = ignoredCustomerTag.split(',');
    setIgnoredCustomerTagsArr(customerTagsArr);
  }, [ignoredCustomerTag]);

  useEffect(() => {
    const tagArr = orderTags.split(',');
    setOrderTagsArr(tagArr);
  }, [orderTags]);

  const handleSetSettings = (type: string) => {
    let input;
    if (type === 'fixed') {
      input =
        rules.length < 1
          ? {
              defaultFee: Number(defaultFee),
              type: 'FIXED',
              storeId,
            }
          : {
              defaultFee: Number(defaultFee),
              type: 'FIXED',
              storeId,
              rules: rules.map((rule, i) => ({
                ...rule,
                rangeLower: Number(rule.rangeLower),
                fee: Number(rule.fee),
                rangeUpper: Number(rule.rangeUpper),
                name: `Rule ${i + 1}`,
              })),
            };
    }
    if (type === 'percentage') {
      const minimumFee = getPriceValue(percentageInput.minimumFee);
      const maximumFee = getPriceValue(percentageInput.maximumFee);

      if (minimumFee > maximumFee) {
        return alert('Minimum fee should be lesser than maximum fee');
      }
      input = {
        ...percentageInput,
        storeId,
        type: 'PERCENTAGE',
        percentage: Number(percentageInput.percentage) / 100,
        minimumFee,
        maximumFee,
      };
    }
    setProtectionSettings({ variables: { input } }).then(() => {
      setShowFixedModal(false);
      setShowPercentageModal(false);
    });
  };

  const handleAddItem = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    type: string,
  ) => {
    const { value } = event.target;
    if (type === 'tags') return setOrderTags(value);
    if (type === 'customerTags') {
      if (!updatedCustomerTags) {
        setUpdatedCustomerTags(true);
      }
      return setIgnoredCustomerTag(value);
    }
    return setIgnoredSku(value);
  };

  const handleRemoveItem = (item: string, type: string) => {
    if (type === 'sku') {
      const filtered = [...ignoredSkuArr].filter((sku) => sku !== item);

      setIgnoredSkuArr(filtered);
      return setIgnoredSku(filtered.join(','));
    }
    if (type === 'customerTags') {
      const filtered = [...ignoredCustomerTagsArr].filter(
        (tag) => tag !== item,
      );
      if (!updatedCustomerTags) {
        setUpdatedCustomerTags(true);
      }
      setIgnoredCustomerTagsArr(filtered);
      return setIgnoredCustomerTag(filtered.join(','));
    }
    const filtered = [...orderTagsArr].filter((tag) => tag !== item);

    setOrderTagsArr(filtered);
    return setOrderTags(filtered.join(','));
  };

  const handleAddNewRule = () => {
    const newObj = {
      rangeLower: '0',
      fee: '0',
      rangeUpper: '0',
    };
    if (rules.length === 10) return;
    setRules([...rules, newObj]);
  };

  const handleRemoveRule = (ruleIndex: number) => {
    const filteredRules = [...rules].filter(
      (rule, index) => index !== ruleIndex,
    );
    setRules(filteredRules);
  };

  const handleChange = (field: string, value: string, index: number) => {
    const objIndex: number = [...rules].findIndex((obj, i) => i === index);
    const rulesCopy = cloneDeep([...rules]);

    if (field === 'rangeLower' && Number(value) >= 0)
      rulesCopy[objIndex].rangeLower = value;
    if (field === 'fee' && Number(value) >= 0) rulesCopy[objIndex].fee = value;
    if (field === 'rangeUpper' && Number(value) >= 0)
      rulesCopy[objIndex].rangeUpper = value;

    setRules(rulesCopy);
  };

  const handleChangePercentage = (field: string, value: string) => {
    if (Number(value) < 0) return;
    setPercentageInput({ ...percentageInput, [field]: value });
  };

  const handleUpdateStore = (payload: any) => {
    const { field, value, input } = payload;
    updateStore({
      variables: {
        storeId,
        set: input
          ? { ...input }
          : {
              [field]: value,
            },
      },
      onCompleted: () => refetchStoreProperties && refetchStoreProperties(),
    })
      .then((data) => {
        return showNotification(
          NotificationTypes.success,
          'Store details updated',
        );
      })
      .catch(() => {
        return showNotification(
          NotificationTypes.error,
          updateStoreError ? updateStoreError.message : 'Details not updated',
        );
      });
  };

  const handleAdvancedUpdate = () => {
    if (ignoredSkuArr.length < 1 && ignoredCustomerTagsArr.length < 1)
      return showNotification(
        NotificationTypes.error,
        'Add an Item SKU or Customer Tags',
      );
    if (updatedCustomerTags) {
      updateCustomerExcludeTags({
        variables: {
          input: {
            storeId,
            tags: ignoredCustomerTagsArr
              .map((i) => i.replaceAll(' ', ''))
              .filter((i) => i !== ''),
          },
        },
      });
    }
    handleUpdateStore({
      input: {
        excludedProductSkus: ignoredSkuArr,
      },
    });
  };

  const handleFulfilment = (option: string) => {
    if (option === 'Other items fulfilled') {
      setitemFulfillmentImmediate(false);
      return handleUpdateStore({
        field: 'itemFulfillmentImmediate',
        value: false,
      });
    }
    setitemFulfillmentImmediate(true);
    handleUpdateStore({
      field: 'itemFulfillmentImmediate',
      value: true,
    });
  };

  if (protectionLoading) return <LoadingScreen />;

  return (
    <div>
      <FixedModal
        show={showFixedModal}
        setShowModal={(val) => setShowFixedModal(val)}
        rules={rules}
        defaultFee={defaultFee}
        addNewRule={handleAddNewRule}
        removeRule={handleRemoveRule}
        onChangeRuleValue={handleChange}
        handleSetRule={handleSetSettings}
        isLoading={loading}
        setDefaultFee={(fee) => setDefaultFee(fee)}
      />
      <PercentageModal
        show={showPercentageModal}
        setShowModal={(val) => setShowPercentageModal(val)}
        isLoading={loading}
        handleSetRule={handleSetSettings}
        percentageInput={percentageInput}
        setInput={(field, value) => handleChangePercentage(field, value)}
      />
      <AllFixedRulesModal
        show={showAllFixedRulesModal}
        setShowModal={(val) => setShowAllFixedRulesModal(val)}
        rules={rules}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="border-b border-gray-200 pt-5 px-4 pb-12 sm:pt-6 sm:px-6">
          <div className="flex items-center justify-between max-w-4xl">
            <div>
              <Text value="Guarantee Fee Type" type="h3" />
              <Text
                value="Choose from fixed or percentage based protection"
                type="body"
                className="mt-1 text-gray-500"
              />
            </div>
            <div>
              <TrackingPortalBtn />
              <CustomerPortalBtn />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 sm:grid-cols-3 mt-10">
            <PackagePlan
              header="Fixed"
              headerClassName="justify-center flex"
              action={
                storeProperties?.protectionSettings?.protectionType === 'FIXED'
                  ? 'Edit'
                  : 'Create rules & Activate'
              }
              actionHandler={() => setShowFixedModal(true)}
              active={
                storeProperties?.protectionSettings?.protectionType === 'FIXED'
              }
              bottomBorder
            >
              <div className={rules.length < 1 ? 'h-52 mb-1' : 'h-48 mb-2'}>
                <div className="flex items-end my-4 px-5 justify-center">
                  <Text
                    value={`${getStoreCurrencyFormatter(
                      storeProperties?.currency,
                      currentProtectionData?.defaultFee ?? defaultFee,
                    )}`}
                    type="h2"
                    className="font-bolder mr-2"
                  />
                  <Text
                    value="Default fee"
                    type="sm"
                    className="text-gray-500 mb-1"
                  />
                </div>
                {currentProtectionData?.protectionType === 'FIXED' &&
                rules.length > 0
                  ? rules.slice(0, 2).map((rule, i) => {
                      return (
                        <div
                          className="flex items-end my-4 px-5 justify-center"
                          key={i}
                        >
                          <Text
                            value={`${getStoreCurrencyFormatter(
                              storeProperties?.currency,
                              rule.fee,
                            )}`}
                            type="h3"
                            className="font-bolder mr-2"
                          />
                          <Text
                            value={`/Orders ${getStoreCurrencyFormatter(
                              storeProperties?.currency,
                              rule.rangeLower,
                            )} - ${getStoreCurrencyFormatter(
                              storeProperties?.currency,
                              rule.rangeUpper,
                            )}`}
                            type="sm"
                            className="text-gray-500 mb-1"
                          />
                        </div>
                      );
                    })
                  : null}
                {currentProtectionData?.protectionType === 'FIXED' &&
                  rules.length > 3 && (
                    <button
                      className="justify-center flex items-center w-full"
                      onClick={() => setShowAllFixedRulesModal(true)}
                    >
                      <Text
                        value="see all rules"
                        className="text-blue-600 cursor-pointer pb-6 pt-1"
                      />
                    </button>
                  )}
              </div>
            </PackagePlan>
            <PackagePlan
              header="Percentage"
              action={
                storeProperties?.protectionSettings?.protectionType ===
                'PERCENTAGE'
                  ? 'Edit'
                  : 'Activate'
              }
              headerClassName="justify-center flex"
              actionHandler={() => setShowPercentageModal(true)}
              active={
                storeProperties?.protectionSettings?.protectionType ===
                'PERCENTAGE'
              }
              bottomBorder
            >
              <div className="h-48 mb-2">
                <>
                  {currentProtectionData?.protectionType === 'PERCENTAGE' &&
                    !isNaN(Number(currentProtectionData?.minimumFee)) && (
                      <div className="flex items-end my-4 px-5 justify-center">
                        <Text
                          value={`${getStoreCurrencyFormatter(
                            storeProperties?.currency,
                            currentProtectionData?.protectionType ===
                              'PERCENTAGE'
                              ? `${currentProtectionData?.minimumFee}`
                              : 0,
                          )}`}
                          type="h2"
                          className="font-bolder mr-2"
                        />
                        <Text
                          value="/Minimum fee"
                          type="sm"
                          className="text-gray-500 mb-1"
                        />
                      </div>
                    )}
                  {currentProtectionData?.protectionType === 'PERCENTAGE' &&
                    !isNaN(Number(currentProtectionData?.maximumFee)) && (
                      <div className="flex items-end my-4 px-5 justify-center">
                        <Text
                          value={`${getStoreCurrencyFormatter(
                            storeProperties?.currency,
                            currentProtectionData?.protectionType ===
                              'PERCENTAGE'
                              ? `${currentProtectionData?.maximumFee}`
                              : 0,
                          )}`}
                          type="h2"
                          className="font-bolder mr-2"
                        />
                        <Text
                          value="/Maximum fee"
                          type="sm"
                          className="text-gray-500 mb-1"
                        />
                      </div>
                    )}
                  <div className="flex items-end my-4 px-5 justify-center">
                    <Text
                      value={`${
                        currentProtectionData?.protectionType === 'PERCENTAGE'
                          ? getPreciseDecimal(
                              currentProtectionData?.percentage * 100,
                            )
                          : '3'
                      }%`}
                      type="h2"
                      className="font-bolder mr-2"
                    />
                    <Text
                      value="/Order"
                      type="sm"
                      className="text-gray-500 mb-1"
                    />
                  </div>
                </>
              </div>
            </PackagePlan>
          </div>
        </div>

        <div className="border-b border-gray-200 pt-5 px-4 pb-8 sm:pt-6 sm:px-6 mt-4">
          <Text value="Cart Settings" type="h3" />
          <div className="mt-3 flex w-full sm:w-64 justify-between">
            <Text value="Show on cart" type="body" className="text-gray-500" />
            <Toggle
              isEnabled={cartToggle}
              onChange={(value) => {
                setCartToggle(value);
                handleUpdateStore({ field: 'widgetShowCart', value });
              }}
            />
          </div>
          <div className="flex w-full sm:w-64 justify-between mt-6">
            <Text value="Auto Opt-in" type="body" className="text-gray-500" />
            <Toggle
              isEnabled={optInToggle}
              onChange={(value) => {
                setOptInToggle(value);
                handleUpdateStore({ field: 'widgetAutoOptIn', value });
              }}
            />
          </div>
          <div className="w-full sm:w-2/6 justify-between mt-6">
            <div className="mt-3 flex w-full  justify-between items-center">
              <Text
                value="Fulfill when"
                type="body"
                className="text-gray-500"
              />
              <Dropdown
                options={['Other items fulfilled', 'Purchased (immediate)']}
                onSelectOption={(option) => handleFulfilment(option)}
                btnClassName="border border-gray-300"
                placeholder="select currency"
                selectedOption={
                  itemFulfillmentImmediate
                    ? 'Purchased (immediate)'
                    : 'Other items fulfilled'
                }
                downCaret
              />
            </div>
          </div>
        </div>
        <div className="border-b border-gray-200 pt-5 px-4 pb-8 sm:pt-6 sm:px-6 mt-4">
          <Text value="Resolutions" type="h3" />
          <div className="mt-3 flex w-full sm:w-64 justify-between">
            <Text
              value="100% Discount on Reorders"
              type="body"
              className="text-gray-500"
            />
            <Toggle
              isEnabled={discountOnReorders}
              onChange={(value) => {
                setDiscountOnReorders(value);
                handleUpdateStore({ field: 'reordersDiscount', value });
              }}
            />
          </div>
        </div>
        <div className="pt-5 px-4 pb-12 sm:pt-6 sm:px-6 mt-6">
          <Text value="Advanced Settings" type="h3" />
          <div className="mt-3 flex w-full sm:w-64 justify-between items-center">
            <div>
              <Text value="Currency" type="body" className="text-gray-500" />
              <Text
                value="(Uses store currency)"
                type="sm"
                className="text-gray-400"
              />
            </div>
            <Dropdown
              options={['USD', 'EUR']}
              onSelectOption={(option) => setCurrency(option)}
              btnClassName="border border-gray-300"
              placeholder="select currency"
              selectedOption={currency}
              downCaret
              disabled
            />
          </div>
          <div className="mt-6">
            <Text
              value="Product Exclusion"
              type="body"
              className="mt-1 text-gray-500 font-bold"
            />
            <Text
              value="Enter SKUs that should be ignored by ShipAid (comma separated values)"
              type="sm"
              className="mt-1 text-gray-500"
            />
            <div className="block sm:flex items-top">
              <textarea
                rows={4}
                className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md sm:w-96 mr-3 mt-3 p-2"
                placeholder="e.g sku1, sku2, sku3 "
                onChange={(e) => handleAddItem(e, 'sku')}
                value={ignoredSku}
              />
              <div className="flex flex-wrap sm:w-96">
                {ignoredSku !== '' &&
                  ignoredSkuArr.length > 0 &&
                  ignoredSkuArr.map((sku, i) => (
                    <Itempill
                      item={sku}
                      key={i}
                      className="mr-2 h-max mt-3"
                      handleRemoveitem={() => handleRemoveItem(sku, 'sku')}
                      showIcon
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-6">
            <Text
              value="Customer Tag Exclusion"
              type="body"
              className="mt-1 text-gray-500 font-bold"
            />
            <Text
              value='Enter Customer Tags that are excluded from "Auto Opt-In" (comma separated values)'
              type="sm"
              className="mt-1 text-gray-500"
            />
            <div className="block sm:flex items-top">
              <textarea
                rows={4}
                className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md sm:w-96 mr-3 mt-3 p-2"
                placeholder="e.g Wholesale, VIP, disableOptIn, etc."
                onChange={(e) => handleAddItem(e, 'customerTags')}
                value={ignoredCustomerTag}
              />
              <div className="flex flex-wrap sm:w-96">
                {ignoredCustomerTag !== '' &&
                  ignoredCustomerTagsArr.length > 0 &&
                  ignoredCustomerTagsArr.map((tag, i) => (
                    <Itempill
                      item={tag}
                      key={i}
                      className="mr-2 h-max mt-3"
                      handleRemoveitem={() =>
                        handleRemoveItem(tag, 'customerTags')
                      }
                      showIcon
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-10">
            <Button
              onClick={() => handleAdvancedUpdate()}
              className="bg-blue-600 hover:bg-blue-700"
              size={SizeEnum.lg}
              isLoading={updateStoreLoading}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
