import React, { useState } from 'react';
import { Text } from 'components/Text';
import { OrderItem } from 'components/OrderItem';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import {
  calculatePriceSum,
  getStoreCurrencyFormatter,
  getStoreCurrencySymbol,
} from 'utils';
import { Button } from 'components/Button';
import { Claim, ClaimItemValues } from 'types/claim';
import { useStore } from 'context/store-context';
import { Order } from 'types/order';

type RefundProps = {
  claimItems: ClaimItemValues[];
  handleRemoveItem?: (order: ClaimItemValues) => void;
  handleRefundItems?: (input: any) => void;
  loading?: boolean;
  claim?: Claim;
  handleQuantityUpdate: (value: string, item: ClaimItemValues) => void;
  staticClaimItems: ClaimItemValues[];
  order?: Order | null;
};

export const Refund = ({
  claimItems,
  handleRefundItems,
  loading,
  handleRemoveItem,
  claim,
  handleQuantityUpdate,
  staticClaimItems,
  order,
}: RefundProps) => {
  const [input, setInput] = useState({
    note: '',
    notify: true,
    amount: '',
  });
  const [isRefundInvalid, setIsRefundInvalid] = useState(false);
  const { storeProperties } = useStore();

  const currencyCode =
    claim?.order?.paymentCurrencyCode ??
    order?.paymentCurrencyCode ??
    storeProperties?.currency;
  const getProtectionFeeTotal = (claim: Claim | undefined) => {
    if (claim?.suggestedRefund?.protectionFee)
      return Number(claim.suggestedRefund.protectionFee);
    return claim?.order?.protectionTotal ?? order?.protectionTotal ?? 0;
  };

  const protectionTotal = getProtectionFeeTotal(claim);

  const getMaxAllowedRefund = (
    claim: Claim | undefined,
    protectionTotal: Number,
  ) => {
    if (claim?.suggestedRefund?.maximumRefundable)
      return (
        Number(claim.suggestedRefund.maximumRefundable) -
        Number(protectionTotal)
      );
    return Number(
      (claim?.order.total ?? order?.total ?? 0) - Number(protectionTotal),
    ); // order total - protection fee
  };

  const maxAllowedRefund = getMaxAllowedRefund(claim, protectionTotal);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    const { value } = e.target;
    if (
      Number(value) > maxAllowedRefund ||
      (value !== '' && Number(value) < 1)
    ) {
      setIsRefundInvalid(true);
    } else {
      setInput({
        ...input,
        [field]: field === 'amount' ? Number(value) : value,
      });
      setIsRefundInvalid(false);
    }
  };

  const handleCheck = (field: string) => {
    setInput({ ...input, [field]: !input.notify });
  };

  const getOrderDiscount = (
    claim: Claim | undefined,
    claimItems: ClaimItemValues[],
  ) => {
    if (!(claimItems && claimItems.length)) return 0;

    const lineItemsTotalDiscount =
      claimItems && claimItems[0].orderItem
        ? Number(
            calculatePriceSum(
              claimItems?.map((item) => {
                return { ...item, ...item.orderItem };
              }),
              'discountTotal',
            ),
          )
        : 0;
    const additionalOrderDiscount = Number(
      claim?.order?.discountTotal ?? order?.discountTotal ?? 0,
    );
    const orderDiscount = Number(
      Number(additionalOrderDiscount) - Number(lineItemsTotalDiscount),
    ).toFixed(2);
    return Number(orderDiscount);
  };

  const getOrderTotal = (claimItems: ClaimItemValues[]) => {
    const orderItemsTotal = claimItems
      ? calculatePriceSum(
          claimItems?.map((item) => {
            const quantity = item.quantity ?? 1;
            const total = item.orderItem.price ?? 0;
            const itemTotal = Number(
              quantity * total -
                (quantity > 0
                  ? Number(
                      quantity *
                        Number(
                          (item?.orderItem?.discountTotal ?? 0) /
                            item?.orderItem.quantity,
                        ),
                    )
                  : 0),
            );
            return { ...item, total: itemTotal };
          }),
          'total',
        )
      : 0;
    // const orderTotal = Number(
    //   Number(orderItemsTotal) - Number(getOrderDiscount(claim, claimItems)),
    // ).toFixed(2);
    return Number(orderItemsTotal);
  };
  const getTotal = (claimItems: ClaimItemValues[]) => {
    const orderTotal = Number(getOrderTotal(claimItems));
    return orderTotal !== 0
      ? getStoreCurrencyFormatter(storeProperties?.currency, orderTotal)
      : '';
  };
  const getTotalAmount = (claimItems: ClaimItemValues[]) => {
    const orderTotal = Number(getOrderTotal(claimItems));
    return orderTotal;
  };

  const refundItemsSubTotal = getTotal(claimItems ?? []);

  const getRefundAmount = (claimItems: ClaimItemValues[]) => {
    const refundAmount = Number(getTotalAmount(claimItems ?? []));
    // const orderTotal = Number(claim?.order.total ?? order?.total ?? 0);
    return refundAmount > maxAllowedRefund ? maxAllowedRefund : refundAmount;
  };

  const refundAmount = input.amount
    ? getStoreCurrencyFormatter(currencyCode, input.amount)
    : getStoreCurrencyFormatter(currencyCode, getRefundAmount(claimItems));

  const refundAmountInNumber = input.amount
    ? Number(input.amount)
    : Number(getRefundAmount(claimItems));

  const handleRefund = () => {
    const refundInput =
      input.amount !== ''
        ? input
        : {
            note: input.note,
            notify: input.notify,
            amount: getRefundAmount(claimItems),
          };
    handleRefundItems && handleRefundItems(refundInput);
  };

  return (
    <div className="block sm:flex sm:gap-4 mt-4">
      <div className="sm:w-2/3 border rounded p-5">
        <div className="flex justify-between px-3 py-2 bg-gray-50 rounded-md">
          <Text value="Refund" type="bold" className="" />
          <QuestionMarkCircleIcon className="h-5 w-5" />
        </div>
        <div className="h-72 overflow-y-auto mt-3">
          {claimItems.length > 0 ? (
            claimItems.map((claimItem, index) => (
              <OrderItem
                key={claimItem.id}
                order={claimItem.orderItem}
                onRemoveItem={() =>
                  handleRemoveItem && handleRemoveItem(claimItem)
                }
                showIcon={false}
                onHandleQuantityUpdate={(value) =>
                  handleQuantityUpdate(value, claimItem)
                }
                staticClaimItems={staticClaimItems} //claimItem that does not change when the quantity is updated
                claimItem={claimItem}
                index={index}
                isDisplay
                shouldUseNumberInputType={true}
              />
            ))
          ) : (
            <Text value="You need items" />
          )}
        </div>
      </div>
      <div className="sm:w-1/3 border rounded p-5 mt-3 sm:mt-0 h-max">
        <Text
          value="Summary"
          type="bold"
          className="px-3 py-2 bg-gray-50 rounded-md"
        />
        <div className="flex justify-between py-3">
          <Text value="Items" type="sm" className="text-gray-600 font-bold" />
          <Text
            value={`${claimItems?.length} items`}
            className="text-blue-500"
          />
        </div>
        <div className="flex justify-between py-3">
          <Text
            value="Order Total"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={getStoreCurrencyFormatter(
              storeProperties?.currency,
              claim?.order.total ?? order?.total,
            )}
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3">
          <Text
            value="Order Sub Total"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={getStoreCurrencyFormatter(
              storeProperties?.currency,
              claim?.order.subtotal ?? order?.subtotal,
            )}
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3">
          <Text
            value="Items Sub Total"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text value={refundItemsSubTotal} className="text-gray-500" />
        </div>
        <div className="flex justify-between py-3">
          <Text
            value="Discount"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={getStoreCurrencyFormatter(
              storeProperties?.currency,
              getOrderDiscount(claim, claimItems),
            )}
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3 mt-1">
          <Text
            value="Guarantee Fee"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={getStoreCurrencyFormatter(currencyCode, protectionTotal)}
            type="sm"
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3 mt-1">
          <Text
            value="Available for refund"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={getStoreCurrencyFormatter(currencyCode, maxAllowedRefund)}
            type="sm"
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3 mt-1">
          <Text
            value="Refund Amount"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text value={refundAmount} type="sm" className="text-gray-500" />
        </div>
        {claim?.notes && (
          <div className=" mt-4 pb-4">
            <Text
              value="Notes"
              type="h4"
              className="px-3 py-1 bg-gray-50 rounded-md"
            />
            <Text value={claim?.notes} className="text-gray-500 mt-4" />
          </div>
        )}
        <div className="mt-6">
          <Text
            value="Custom refund amount (optional)"
            type="bold"
            className="text-gray-500 mb-4"
          />
          <div className="relative">
            <span className="absolute left-2 top-[6px]">
              {getStoreCurrencySymbol(storeProperties?.currency).charAt(0)}
            </span>
            <input
              type="number"
              name="amount"
              min={1}
              className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2 pl-5"
              placeholder={`e.g ${claimItems[0]?.orderItem?.price ?? '10.00'}`}
              onChange={(e) => handleChange(e, 'amount')}
            />
          </div>
          {isRefundInvalid ? (
            <Text
              value="Can’t refund more than available amount or less than $1"
              type="body"
              className="text-red-500"
            />
          ) : null}
          <Text value="Notes" type="bold" className="text-gray-500 mt-4" />
          <textarea
            rows={4}
            className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md mr-3 mt-3 p-2"
            placeholder="Notes"
            onChange={(e) => handleChange(e, 'note')}
          />
          <Text
            type="sm"
            value={
              'NB - Resolution notes are visible to customers on the customer portal'
            }
            className="text-gray-400"
          />
          <div className="mt-5 flex items-center">
            <input
              name="notify"
              type="checkbox"
              className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded mr-1"
              onChange={(e) => handleCheck('notify')}
              checked={input.notify}
            />
            <Text
              value="Send a notification to customers"
              className="text-gray-500"
              type="sm"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            onClick={handleRefund}
            isLoading={loading}
            disabled={isRefundInvalid || refundAmountInNumber < 1}
          >
            Refund {refundAmount}
          </Button>
        </div>
      </div>
    </div>
  );
};
