import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/Button';
import { format } from 'date-fns';
import { MerchantUser } from 'types/admin';
import { Text } from 'components/Text';
import { StoreValues } from 'types/store';

export const merchantColumns = [
  {
    name: 'Company Name',
    id: 1,
    render: (item: MerchantUser) => (
      <div className="flex items-center">
        <img
          className="mr-4 h-10 w-10 sm:h-10 sm:w-10 rounded-full"
          src={item.avatarUrl}
          alt={item.displayName}
        />
        <Link
          to={`/merchants/${item.id}`}
          className="text-xs font-medium text-blue-500 group-hover:text-blue-700"
        >
          {item.displayName}
        </Link>
      </div>
    ),
  },
  {
    name: 'Email',
    id: 2,
    key: 'email',
  },
  {
    name: 'Creation Date',
    id: 2,
    render: (item: MerchantUser) => (
      <Text value={format(new Date(item.createdAt), 'yyyy-MM-dd')} />
    ),
  },
  {
    name: 'Last Active',
    id: 3,
    render: (item: MerchantUser) => (
      <Text
        value={
          item.lastSeen
            ? format(new Date(item.lastSeen), 'yyyy-MM-dd hh:mm:ss aa')
            : ''
        }
      />
    ),
  },
];

export const merchanStoreColumns = [
  {
    name: 'Domain Name',
    id: 1,
    render: (item: StoreValues) => <Text value={item.store} />,
  },
  {
    name: 'Created On',
    id: 2,
    render: (item: StoreValues) => (
      <Text value={format(new Date(item.createdAt), 'yyyy-MM-dd')} />
    ),
  },
  {
    name: 'Type',
    id: 3,
    key: 'type',
  },
  {
    name: 'Widget Auto Opt In',
    id: 4,
    render: (item: StoreValues) => (
      <Text value={item.widgetAutoOptIn ? 'Yes' : 'No'} />
    ),
  },
  {
    name: 'Widget Show Cart',
    id: 5,
    render: (item: StoreValues) => (
      <Text value={item.widgetShowCart ? 'Yes' : 'No'} />
    ),
  },
  {
    name: 'Credit',
    id: 5,
    render: (item: StoreValues) => <Text value={`$${item.storeCredit}`} />,
  },
  {
    name: 'Actions',
    id: 6,
    render: (item: any) => (
      <>
        <Button
          onClick={item.godModeLogin}
          className="bg-blue-600 hover:bg-blue-700"
        >
          Login using Store
        </Button>
        <Button
          onClick={item.handleAdminStoreCredit}
          className="bg-blue-600 hover:bg-blue-700 ml-4"
        >
          Credit
        </Button>
      </>
    ),
  },
];
