import { useState } from 'react';
import { PhoneIcon } from '@heroicons/react/outline';
import { useMutation } from '@apollo/client';
import { Text } from 'components/Text';
import { BodyText } from 'components/MardownBodyText';
import { Button } from 'components/Button';
import { Tabs } from 'components/Tabs';
import { withNotification } from 'components/Notification';
import { useStore } from 'context/store-context';
import { useUser } from 'context/user-context';
import { SEND_INSTALL_REQUEST } from 'gql/mutations';
import { NotificationTypes, apiErrorHandler } from 'utils';
import { ManualInstallInstructions } from './ManualInstallInstructions';

export const Install = withNotification(({ showNotification }: any) => {
  const store = useStore();
  const { user } = useUser();

  const [selectedTab, setSelectedTab] = useState('Free Expert Installation');

  const [sendInstallRequest] = useMutation(SEND_INSTALL_REQUEST, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted() {
      showNotification(NotificationTypes.success, 'Install request is sent!');
    },
  });

  const handleSendInstallRequest = async () => {
    try {
      await sendInstallRequest({
        variables: {
          input: {
            storeId: store.storeId,
            email: user?.email,
            name: user?.displayName,
            storeName: store.storeName,
          },
        },
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  const paragraphs = [
    {
      text: `One of the ways you can install **Shipaid** is by using [**Shopify's Theme app extensions**](https://shopify.dev/apps/online-store/theme-app-extensions) which make it super easy to configure the **Shipaid** app widget without the need to manually edit your store theme's code. In your theme editor, you'll be able to select the **Shipaid** Widget from the list of available **App blocks** and drag and drop in your desired location.`,
    },
    {
      text: `Installing the **Shipaid** app in your store adds the **Shipaid widget** App block to your theme. When added to your store, the widget can be used by shoppers to add or remove shipping protection and display relevant information about the shipping protection such as price, whether or not shipping protective is active and links to learn more.`,
    },
    {
      text: `The **Shipaid** app block is designed to be used on the **Cart Template** and is best positioned in the **Subtotal** section right above the **Checkout button** block. This will allow your customers select their desired shipping protection as part of the checkout process.`,
      image: `https://res.cloudinary.com/media-resource/image/upload/v1659071448/2022-07-29_01-09-46_bdgya7.gif`,
    },
    {
      text: `To add the **Shipaid** widget block, click *Add block* on the left hand menu and in the dropdown that appears, look for the ***Shipaid** Widget* under the *Apps* section. Once you've selected the widget, you can drag it to it's desired location within the chosen section.`,
      image: `https://res.cloudinary.com/media-resource/image/upload/v1659070800/shipaid-dev/image_nhhkso.png`,
    },
    {
      text: `Modifying the Shipaid app block is easy too. To disable it, you can click on the eye icon that shows up when you hover over the app block. You can also click on the **Shipaid Widget** block to access more options including the option to delete the widget. To add the widget back again, just follow the same steps above.`,
      image: `https://res.cloudinary.com/media-resource/image/upload/v1659072512/2022-07-29_01-16-23_omjwj7.gif`,
      imageStyle: { width: 720, height: 480 },
    },
  ];

  const renderRequestCallButton = ({ className }: { className?: string }) => (
    <a
      href="https://calendly.com/shipaid-support/install"
      target="_blank"
      rel="noreferrer"
    >
      <Button className={className} isRounded onClick={() => {}}>
        <PhoneIcon className="mr-4" width={20} /> Book An Expert Call
      </Button>
    </a>
  );

  const renderRequestInstallButton = ({
    className,
  }: {
    className?: string;
  }) => (
    <Button className={className} isRounded onClick={handleSendInstallRequest}>
      Request Free Expert Installation
    </Button>
  );

  const renderExpertFlow = () => {
    return (
      <div className="mt-16">
        <p style={{ maxWidth: '756px', margin: 'auto' }}>
          We strongly encourage you to take advantage of our Free Expert
          Installation service to seamlessly integrate the Delivery Guarantee
          widget into your cart or checkout process. This ensures maximum
          conversion rates and a bug-free experience. After installing the app,
          you will receive a Shopify request for access to your theme code.
          Kindly approve this request, and our team will complete the
          installation within 24 hours or less. The installation will not
          disrupt your live store and will give you a chance to review it before
          it is published. If you do not receive the request, simply click the
          &ldquo;Request&ldquo; button below, and we will promptly send it your
          way. Trust our expertise for an optimized and hassle-free
          implementation.
        </p>
        <div
          className="flex justify-start mb-4"
          style={{ maxWidth: '756px', margin: 'auto' }}
        >
          {renderRequestInstallButton({ className: 'mt-6' })}
        </div>
        <p className="mt-6 mx-auto" style={{ maxWidth: '756px' }}>
          Feel free to schedule a call with our install team using be button
          below or email us at support@shipaid.com to go over any specifics.
        </p>
        <div
          className="flex justify-start mb-4"
          style={{ maxWidth: '756px', margin: 'auto' }}
        >
          {renderRequestCallButton({ className: 'mt-6' })}
        </div>
      </div>
    );
  };

  const renderAutomaticFlow = () => {
    return (
      <div className="mt-16">
        <div style={{ maxWidth: '756px', margin: 'auto' }}>
          {paragraphs.map(
            (p: {
              image?: string;
              imageStyle?: { width: number; height: number };
              text: string;
            }) => {
              return (
                <div key={p.text} className="mb-4 items-center flex flex-col">
                  {p.image && (
                    <img
                      alt={p.text}
                      style={p.imageStyle || { width: 360, height: 324 }}
                      className="my-4 border border-gray-300 object-cover block margin-auto"
                      src={p.image}
                    />
                  )}
                  <BodyText className="max-w-2xl" text={p.text} />
                </div>
              );
            },
          )}
        </div>
        <div className="flex justify-end mb-4">
          {renderRequestCallButton({ className: 'mt-6' })}
        </div>
      </div>
    );
  };

  const renderManualFlow = () => {
    return (
      <div className="mt-16">
        <ManualInstallInstructions />
        <div className="flex justify-end mb-4">
          {renderRequestCallButton({ className: 'mt-7' })}
        </div>
      </div>
    );
  };

  return (
    <div className="pb-8">
      <div className="max-w-7xl mx-auto mb-12 px-4 sm:px-6 md:px-8">
        <Text value="Install" type="h1" />
        {/* <BodyText
          className="max-w-4xl mt-4"
          text="Thank you for choosing **Shipaid**! We offer two ways to install Shipaid to your store depending on your Shopify theme and preference. The instructions below will get you up and running in no time. However, if you require any assistance, please reach out to us to schedule a free expert installation."
        />
        {renderRequestInstallButton({ className: 'mt-4' })} */}
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Tabs
          tabs={[
            { name: 'Free Expert Installation' },
            { name: 'Manual Installation' },
            { name: 'Auto Installation' },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab === 'Auto Installation'
          ? renderAutomaticFlow()
          : selectedTab === 'Manual Installation'
          ? renderManualFlow()
          : renderExpertFlow()}
      </div>
    </div>
  );
});

export default Install;
